import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {RouterProvider} from "react-router-dom";
import React from "react";
import {router} from "./routes/router";

function App() {
  return (
    <>
      <Header/>
      <main className="content">
        <RouterProvider router={router} />
      </main>
      <Footer/>
    </>
  );
}

export default App;
