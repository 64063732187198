import React from 'react';

export const Banner = () => {
  const banner_obj = {
    image: "https://cdn.prod.website-files.com/646a6ec3f634076bc7bf77f2/6480fe4aee2555588b20b934_Who%20We%20Are%20Hero-p-500.png",
    title: "Who We Are",
    text: "Унікальні вироби для вашого дому — аксесуари, яких ви не знайдете в магазинах"
  }
  return (
    <div className='full-width banner'>
      <div className="container banner__container">
        <div className="banner__title-container">
          <h2 className='banner__title'>{banner_obj.title}</h2>
          <p className='banner__text'>{banner_obj.text}</p>
        </div>
        <div className="img-container banner__img-container">
          <img src={banner_obj.image} alt="Banner" className="banner__img"/>
        </div>
      </div>
    </div>
  );
};