import React from 'react';

export const AboutUs = () => {
  const about_us_obj = {
    description: "Tales Solution LLC створена для тих, хто цінує унікальність. Наша місія —\n" +
      "            запропонувати товари, які задовольняють специфічні потреби сімей, роблять їхній дім затишним та\n" +
      "            функціональним. Більшість наших виробів — це унікальні товари, доступні лише онлайн. Вони поєднують\n" +
      "            ексклюзивний дизайн та високу якість, які важко знайти в звичайних магазинах.",
    main_image: "https://cdn.prod.website-files.com/646a6ec3f634076bc7bf77f2/6480fe4aee2555588b20b934_Who%20We%20Are%20Hero-p-500.png",
    main_title: "About Us",
    title: "Наші переваги:",
    tools: ["Унікальний дизайн", "Постійне оновлення каталогу", "Спеціальні акційні пропозиції"]
  }

  return (
    <div id="about-us" className='full-width about-us'>
      <div className="container about-us__container">
        <div className="about-us__container-top">
          <div className="about-us__img-container">
            <img className="about-us__img" src={about_us_obj.main_image} alt="About Us"/>
          </div>
          <div className="about-us__text-container">
            <h2 className='title about-us__title'>{about_us_obj.main_title}</h2>
            <p className='about-us__text'>{about_us_obj.description}</p>
          </div>
        </div>
        <div className="about-us__container-bottom">
          <div className="about-us__text-container">
            <h2 className='title about-us__title'>{about_us_obj.title}</h2>
          </div>
          <ul className="about-us__list">
            {about_us_obj.tools.map((item, index) => (
              <li key={index} className="about-us__item">{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};