import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import {HomePage} from "../pages/HomePage";
import {ProductPage} from "../pages/ProductPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/product",
    element: <ProductPage/>,
  },
]);