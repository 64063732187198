import React from 'react';

export const Header = () => {
  return (
    <header className='header'>
      <div className="container container__horizontal header__container">
        <div className="header__logo-container">
          <a className='header__logo' href="/">Tales Solution LLC</a>
          <span className='header__slogan'>Ексклюзивні вироби для дому — лише онлайн</span>
        </div>
        <nav className='header__navigation navigation'>
          <ul className='container__horizontal navigation__list'>
            <li className='navigation__item'>
              <a href="#about-us" className='navigation__link'>Про нас</a></li>
            <li className='navigation__item'><a href="#contact" className='navigation__link'>Контакти</a></li>
          </ul>
        </nav>
        <div className="header__product">
          <a className='header__product-link' href="/product">Buy</a>
        </div>
      </div>
    </header>
  );
};