import React from 'react';

export const Product = () => {
  const product_obj = {
    img: 'https://otobottle.com/cdn/shop/files/shop.webp?v=1727269071&width=990',
    title: "Hydrogen Rich Water Bottle",
    price: "$59.95 USD",
    description: "Your daily dose of high quality hydrogen water to restore energy and support overall health."
  }
  return (
    <div className='product'>
      <div className="container product__container">
        <div className="img-container product__img-container">
          <img className='product__img' src={product_obj.img} alt={product_obj.title}/>
        </div>
      </div>
      <div className="container__vertical product__info-container">
        <h2 className='title product__title'>{product_obj.title}</h2>
        <p className='product__price'>{product_obj.price}</p>
        <p className='product__description'>{product_obj.description}</p>
        <a href="mailto:contact@talessolutionllc.com" className="product__buy">Buy</a>
      </div>
    </div>
  );
};