import React from 'react';

export const Advantages = () => {
  const advantages_obj = {
    title: "Чому Tales Solution LLC?",
    items: [
      {
        title: "Прозорість",
        description: "Ніяких прихованих платежів, вся інформація про товари та доставку доступна заздалегідь."
      },
      {title: "Постійні оновлення каталогу", description: "Ми регулярно додаємо нові ексклюзивні вироби."},
      {title: "Акції та спеціальні пропозиції", description: "Постійні знижки на наші найкращі продукти."},
    ]
  }

  return (
    <div className='full-width advantages'>
      <div className="container container__vertical advantages__container">
        <h2 className='title advantages__title'>{advantages_obj.title}</h2>
        <ul className="advantages__list">
          {advantages_obj.items.map((item, index) => (
            <li key={index} className="container__vertical advantages__item">
              <h3 className="subtitle advantages__item-subtitle">{item.title}</h3>
              <p className='advantages__item-description'>{item.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};