import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export const ContactForm = () => {
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Ім'я має містити щонайменше 2 символи")
      .required("Обов'язкове поле"),
    email: Yup.string()
      .email("Невірний формат електронної пошти")
      .required("Обов'язкове поле"),
    message: Yup.string()
      .min(10, "Повідомлення має містити щонайменше 10 символів")
      .required("Обов'язкове поле"),
  });
  return (
    <div id="contact" className="full-width contact-form">
      <div className="container contact-form__container">
        <h1 className="title contact-form__title">Зв’яжіться з нами</h1>
        <Formik
          initialValues={{ name: "", email: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            alert(
              `Електронна пошта: contact@talessolutionllc.com\n\nДані форми:\n${JSON.stringify(
                values,
                null,
                2
              )}`
            );
            resetForm();
          }}
        >
          {() => (
            <Form className="contact-form__form">
              <div>
                <label htmlFor="name">Ім'я</label>
                <Field name="name" type="text" />
                <ErrorMessage
                  name="name"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              <div>
                <label htmlFor="email">Email</label>
                <Field name="email" type="email" />
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              <div>
                <label htmlFor="message">Повідомлення</label>
                <Field name="message" as="textarea" />
                <ErrorMessage
                  name="message"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              <a href="mailto:contact@talessolutionllc.com" className="contact-form__button">Відправити</a>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
