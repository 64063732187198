import React from 'react';
import {Banner} from "../components/HomePage/Banner";
import {AboutUs} from "../components/HomePage/AboutUs";
import {Advantages} from "../components/HomePage/Advantages";
import {ContactForm} from "../components/HomePage/ContactForm";

export const HomePage = () => {
  return (
    <>
      <Banner/>
      <AboutUs/>
      <Advantages/>
      <ContactForm/>
    </>
  );
};