import React from 'react';

export const Footer = () => {
  return (
    <footer className='footer'>
      <div className="container container__vertical footer__container">
        <div className="footer__logo-container">
          <a className='footer__logo' href="/">Tales Solution LLC</a>
        </div>
        <nav className='footer__navigation navigation'>
          <h4 className='subtitle navigation__title'>Info</h4>
          <ul className='container__vertical navigation__list'>
            <li className='navigation__item'>
              <a href="#about-us" className='navigation__link'>Про нас</a></li>
            <li className='navigation__item'><a href="#contact" className='navigation__link'>Контакти</a></li>
          </ul>
        </nav>
        <div className="footer__copyright"><span>©2024 Tales Solution, LLC. All Rights Reserved.</span></div>
      </div>
    </footer>
  );
};